import React, { useState, useEffect } from 'react';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';

const Terms = () => {
 
  return (
    <Layout>
 <section className="bg-gray-100 py-20">
    <div className="container mx-auto px-4 lg:flex lg:justify-between">
      {/* Side panel for navigation */}
      <div className="hidden lg:block lg:w-1/4 lg:sticky lg:top-24">
        <nav>
          <ul className="bg-white p-4 rounded-lg shadow-md">
            <li className="mb-4">
              <a href="#introduction" className="text-blue-500 hover:underline">Introduction</a>
            </li>
            <li className="mb-4">
              <a href="#registration" className="text-blue-500 hover:underline">Registration</a>
            </li>
            <li className="mb-4">
              <a href="#products" className="text-blue-500 hover:underline">Products</a>
            </li>
            <li className="mb-4">
              <a href="#payments" className="text-blue-500 hover:underline">Payments</a>
            </li>
            <li className="mb-4">
              <a href="#shipping" className="text-blue-500 hover:underline">Shipping</a>
            </li>
            <li className="mb-4">
              <a href="#returns" className="text-blue-500 hover:underline">Returns & Refunds</a>
            </li>
            <li className="mb-4">
              <a href="#privacy" className="text-blue-500 hover:underline">Privacy Policy</a>
            </li>
            <li className="mb-4">
              <a href="#contact" className="text-blue-500 hover:underline">Contact Information</a>
            </li>
          </ul>
        </nav>
      </div>

      {/* Main content */}
      <div className="lg:w-3/4">
        <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-md">
          <h1 id="introduction" className="text-4xl lg:text-5xl xl:text-6xl font-bold mb-10">Terms & Conditions</h1>

          <section id="introduction">
            <h2 className="text-2xl font-bold mb-6">Introduction</h2>
            <p className="mb-4">
              Welcome to froot™, South Africa's premier tech destination. Our commitment is to provide you with innovative technology while ensuring transparency and a seamless shopping experience. By using our services, you agree to abide by the terms and conditions outlined below.
            </p>
          </section>

          <section id="registration">
            <h2 className="text-2xl font-bold mb-6">Registration</h2>
            <p className="mb-4">
              To access certain features of our platform or make purchases, you may need to register an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
            </p>
            <p className="mb-4">
              We reserve the right to refuse service, terminate accounts, or remove or edit content if we believe the user's conduct violates applicable laws or is harmful to our interests.
            </p>
          </section>

          <section id="products">
            <h2 className="text-2xl font-bold mb-6">Products</h2>
            <p className="mb-4">
              We strive to provide accurate and up-to-date information regarding our products, including descriptions, pricing, availability, and shipping details. However, we do not guarantee that all product information is complete, current, or error-free.
            </p>
            <p className="mb-4">
              Product images are for illustrative purposes only and may differ from the actual product. We reserve the right to modify or discontinue products without prior notice.
            </p>
          </section>

          <section id="payments">
            <h2 className="text-2xl font-bold mb-6">Payments</h2>
            <p className="mb-4">
              We accept various payment methods for your convenience. By making a purchase, you agree to provide accurate payment information and authorize us to charge the total amount for the transaction, including applicable taxes and fees.
            </p>
            <p className="mb-4">
              Your payment details are encrypted and secure. We do not store or have access to your payment information.
            </p>
          </section>

          <section id="shipping">
            <h2 className="text-2xl font-bold mb-6">Shipping</h2>
            <p className="mb-4">
              We aim to deliver products within the specified timeframe. Delivery times may vary based on product availability, location, and other factors. Delays may occur due to unforeseen circumstances.
            </p>
            <p className="mb-4">
              Shipping costs and estimated delivery times are provided during the checkout process. We are not responsible for delays caused by third-party shipping carriers.
            </p>
          </section>

          <section id="returns">
            <h2 className="text-2xl font-bold mb-6">Returns & Refunds</h2>
            <p className="mb-4">
              Our returns and refunds policy outlines the process for returning items and obtaining refunds. Please refer to our policy for detailed information on eligible items, return conditions, and refund processing.
            </p>
            <p className="mb-4">
              Products must be returned in their original condition and packaging. We reserve the right to refuse returns that do not meet our policy criteria.
            </p>
          </section>

          <section id="privacy">
            <h2 className="text-2xl font-bold mb-6">Privacy Policy</h2>
            <p className="mb-4">
              Protecting your privacy is important to us. Our privacy policy explains how we collect, use, and protect your personal information when you use our services. We do not sell or share your information with third parties without your consent.
            </p>
            <p className="mb-4">
              By using our platform, you agree to the collection and use of your information as described in our privacy policy.
            </p>
          </section>

          <section id="contact">
            <h2 className="text-2xl font-bold mb-6">Contact Information</h2>
            <p className="mb-4">
              If you have any questions or concerns about our terms and conditions, please contact us at info@froot.shop.
            </p>
            <p className="mb-4">
              Your feedback is valuable to us, and we aim to address any inquiries or issues promptly and effectively.
            </p>
          </section>

          <p className="text-gray-600 text-center mt-8">Premium Tech Accessories.</p>
        </div>
      </div>
    </div>
  </section>
  </Layout>
  );
};

export default Terms;